<template>
  <getecma-single-content-layout>
    <template #content>
      <div class="d-flex align-items--center direction--column">
        <getecma-icon size="xxl" icon="person-x-fill" />
        <getecma-header v-if="creating">Você não pode criar um grupo</getecma-header>
        <getecma-header v-else>Você não pode editar um grupo</getecma-header>
        <getecma-p>
          Você não possui a permissão certa para isso
        </getecma-p>
        <br>
        <getecma-button theme="primary" @click="goToBasePage()">
          Voltar à página
        </getecma-button>
      </div>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { goToBasePage } from '@/router/route.service';
import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';

export default {
  name: 'GetecmaGroupForbiddenPage',
  components: {
    GetecmaSingleContentLayout,
  },
  computed: {
    creating() {
      return this.$route.params?.creating;
    },
  },
  methods: {
    goToBasePage,
  },
};
</script>
